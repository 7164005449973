import React from 'react';
import './about.css';

const About = () => {
  return (
    <div className="about-container">
      <p>
        <span role="img" aria-label="wave">
          👋
        </span>{' '}
        Hi there!, with a passion for seamless workflows and efficient data management, we embarked on a journey to create a powerful React app that simplifies the exchange of information between these two platforms.
      </p>

      <p>
        <span role="img" aria-label="rocket">
          🚀
        </span>{' '}
        Our mission is to empower businesses by streamlining their processes, enabling them to focus on what matters most. Leveraging cutting-edge technologies, We have crafted a solution that effortlessly fetches and exports data from Monday to Odoo, and vice versa, making your daily operations smoother than ever.
      </p>

      <p>
        <span role="img" aria-label="tools">
          🛠️
        </span>{' '}
        Armed with a toolkit of innovative technologies, we have poured our expertise into developing a solution that not only meets your integration needs but also adds a touch of simplicity and effectiveness to your workflow. The journey of overcoming challenges and envisioning a more connected digital landscape has been both inspiring and rewarding.
      </p>

      <p>
        <span role="img" aria-label="globe">
          🌐
        </span>{' '}
        Explore the future of collaboration with our app. Whether you're a seasoned professional or just getting started, we invite you to experience the power of streamlined data exchange. Feel free to reach out with any questions or collaboration opportunities—let's elevate your business together!
      </p>

      <p>
        Happy integrating!{' '}
        <span role="img" aria-label="rocket">
          🚀
        </span>
      </p>
    </div>
  );
};

export default About;
