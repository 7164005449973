import {React,useState, useEffect} from 'react';
import axios from 'axios';
import HandleOddoProjects from './HandleOddoProjects';
import { toast } from "react-toastify";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

const OdooProjects = ({token}) => {
  const [projects1, setProjects] = useState([]);
  const fetchData =  async () => {
    try {

      const response = await axios.post('https://sync-odoo.dev-techloyce.com/api/search-projects');
      const { projects } = response.data;
      setProjects(projects);
      // console.log('Projects: ', projects);
      monday.execute('valueCreatedForUser');
    } catch (error) {
      // console.error('Error fetching projects:', error);
      toast.error("Import failed.Error fetching projects!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        // className: "foo-bar",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array to run the effect only once

  return (
    <div>
      {projects1.length>0 && <HandleOddoProjects projects = {projects1} token={token} />}
        
    </div>
  )
}

export default OdooProjects
