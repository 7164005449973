import React from 'react';
import MondayUsers from './MondayUsers';

const HandleFetchUsers = async (token) => {
  try {
    // console.log("API Key in Handle Fetch Users:", token);
    const usersData = await MondayUsers(token);
    // console.log("Users in Handle Fetch Users:", usersData);

    const backendEndpoint = 'https://sync-odoo.dev-techloyce.com/api/export-users';
    const response = await fetch(backendEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        usersData: usersData.data,
      })
    });

    if (response.ok) {
      // console.log('Contact data sent to the backend successfully');
    } else {
      // console.error('Failed to send contact data to the backend:', response.status, response.statusText);
    }
  } catch (error) {
    console.error("Failed to fetch contact data:", error);
  }

  return (
    <div>
      {/* You can render any React component here if needed */}
    </div>
  );
};

export default HandleFetchUsers;
