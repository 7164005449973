import React from 'react';
import MondayBoards from './MondayBoards';

const HandleFetchBoards = async (token) => {
  try {
    const boardsData = await MondayBoards(token);
    // console.log('Boards in Handle Fetch Boards:', boardsData);

    const backendEndpoint = 'https://sync-odoo.dev-techloyce.com/api/export-board';

    // Ensure boardsData is always an array
    const boardDataArray = Array.isArray(boardsData) ? boardsData : [boardsData];
    // console.log('Array of BoardData:', boardDataArray);

    const response = await fetch(backendEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        boardsData: boardDataArray, // Send an array of boards to the backend
      }),
    });

    if (response.ok) {
      // console.log('Boards data sent to the backend successfully');
    } else {
      console.error('Failed to send boards data to the backend:', response.status, response.statusText);

      // Handle error message from backend
      const errorResponse = await response.json();
      console.error('Error message from backend:', errorResponse.message);
    }
  } catch (error) {
    console.error('Failed to fetch and send boards:', error);
  }

  return (
    <div>
    </div>
  );
};

export default HandleFetchBoards;
