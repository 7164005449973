import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "./OdooLogin.css";
import "react-toastify/dist/ReactToastify.css";


const OdooLogin = () => {
  const [url, seturl] = useState("");
  const [db, setDb] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleurlChange = (e) => {
    seturl(e.target.value);
    setError("");
  };

  const handleDbChange = (e) => {
    setDb(e.target.value);
    setError("");
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const proceedForConnection = async () => {
    if (!url || !db || !username || !password) {
      setError("All fields are required");
      return;
    }


    try {
      const response = await axios.post("https://sync-odoo.dev-techloyce.com/api/login", {
        url,
        db,
        username,
        password,
      });

      // console.log("Backend response:", response.data);
      if (response.data.message === "Successfully connected to Odoo server") {
        toast.success("Connection successful!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          // className: "foo-bar",
        });
      }
    } catch (err) {
      // console.error("Error:", err);
      toast.error("Connection failed.Try again!" ,{
        position: toast.POSITION.BOTTOM_RIGHT,
        // className: "foo-bar",
      });
    }
  };

  return (
    <div className="odoo-div" style={{ backgroundColor: "white" }}>
      <h1 className="text-3xl font-bold text-black-500">Odoo-Connection</h1>
      <br></br>
      <div className="grid grid-cols-2 gap-4 mb-8">
        <div className="flex flex-col">
          <label htmlFor="url">URL:</label>
          <input
            type="text"
            id="url"
            value={url}
            onChange={handleurlChange}
            className="p-2 w-full border border-gray-300"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="db">Database:</label>
          <input
            type="text"
            id="db"
            value={db}
            onChange={handleDbChange}
            className="p-2 w-full border border-gray-300"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            className="p-2 w-full border border-gray-300"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            className="p-2 w-full border border-gray-300"
          />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <button
          className="custom-width p-3 bg-blue-500 text-white rounded"
          onClick={proceedForConnection}
        >
          Build Connection
        </button>
        {error && <div className="text-red-500 mt-2">{error}</div>}
      </div>
    </div>
  );
};

export default OdooLogin;
