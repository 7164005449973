import React, { useEffect } from "react";
import { toast } from "react-toastify";

const HandleOddoContacts = ({ partners, token }) => {
  // console.log("Partners in HandleOddoContacts", partners);

  const findExistingBoard = async (partner) => {
    // Check if the board already exists
    const findBoardQuery = `
      query findBoard {
        boards (limit: 1, name: "${partner.name}") {
          id
        }
      }
    `;

    const findBoardResponse = await fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: findBoardQuery,
      }),
    });

    const findBoardData = await findBoardResponse.json();

    if (findBoardData.errors) {
      // console.error(`Error finding board for partner "${partner.name}":`, findBoardData.errors);
      return null;
    }

    return findBoardData.data.boards.length > 0 ? findBoardData.data.boards[0].id : null;
  };

  const updateBoard = async (boardId, partner) => {
    // Implement the logic to update the existing board as needed
    // console.log(`Updating board for partner "${partner.name}". Board ID:`, boardId);
    // You can add your logic to update the existing board here
  };

  const createBoard = async (partner) => {
    // Check if the board already exists
    const existingBoardId = await findExistingBoard(partner);

    if (existingBoardId) {
      // Board already exists, update the existing board
      await updateBoard(existingBoardId, partner);
      return existingBoardId;
    }

    // Create a new board if it doesn't exist
    const createBoardQuery = `
      mutation createBoard {
        create_board(
          board_name: "${partner.name}",
          board_kind: public
        ) {
          id
        }
      }
    `;

    const createBoardResponse = await fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: createBoardQuery,
      }),
    });

    const createBoardData = await createBoardResponse.json();

    if (createBoardData.errors) {
      // console.error(`Error creating board for partner "${partner.name}":`, createBoardData.errors);
      return null;
    }

    const boardId = createBoardData.data.create_board.id;
    // console.log(`Board for partner "${partner.name}" created successfully. Board ID:`, boardId);

    return boardId;
  };

  const createItem = async (boardId, partner) => {
    // Create an item on the board using the partner's email as the item name
    const createItemQuery = `
      mutation createContact {
        create_item(
          board_id: ${boardId},
          item_name: "${String(partner.email)}"
        ) {
          id
        }
      }
    `;

    const createItemResponse = await fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: createItemQuery,
      }),
    });

    const itemData = await createItemResponse.json();
    // console.log("Response for contact:", itemData);

    return itemData; // Return the data for further processing or error-checking
  };

  const importContacts = async () => {
    try {
      const responses = await Promise.all(
        partners.map(async (partner, index) => {
          // Check if the board already exists for the partner
          const existingBoardId = await findExistingBoard(partner);
  
          if (existingBoardId) {
            // Board already exists, update the existing board
            await updateBoard(existingBoardId, partner);
          } else {
            // Create a new board for each partner
            const boardId = await createBoard(partner);
  
            if (!boardId) {
              // Handle error if board creation fails
              return null;
            }
  
            // Create an item on the board using the partner's email as the item name
            await createItem(boardId, partner);
          }
        })
      );
  
      toast.success(`Contacts imported successfully!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        // className: "foo-bar",
      });
  
      // Additional error-checking can be performed here if needed
      responses.forEach((data, index) => {
        if (data && data.errors) {
          console.error(`Error in promise ${index}:`, data.errors);
        }
      });
    } catch (error) {
      console.error("Error in importContacts:", error);
      toast.error("Import failed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        // className: "foo-bar",
      });
    }
  };

  useEffect(() => {
    importContacts();
  }, [token]); // Include apiKey in the dependency array to ensure useEffect runs when apiKey changes

  return <div></div>;
};

export default HandleOddoContacts;
