import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MondayAuth from "./auth/MondayAuth";
import Dashboard from "./dashboard/Dashboard";
import Example from "./tailwind";
import About from "./about";
import DrawerAppBar from "./navBar";

function App() {
  useEffect(() => {
    // Check if the current route is not the landing page
    if (window.location.pathname !== "/") {
      // Apply styles directly to the body element
      document.body.style.background =
        "linear-gradient(to bottom, #ece8e8e6 50%, #a695c9 100%)";
      document.body.style.minHeight = "100vh";
      document.body.style.margin = "0";
      document.body.style.display = "flex";
      document.body.style.flexDirection = "column";
    }

    // Cleanup on component unmount
    if(window.location.pathname === "/") {
      document.body.style.background = "";
      document.body.style.minHeight = "";
      document.body.style.margin = "";
      document.body.style.display = "";
      document.body.style.flexDirection = "";
    };
  }, []); // Empty dependency array ensures that the effect runs only once

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Example />} />
        <Route path="/monday-auth" element={<MondayAuth />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
      </Routes>
      {window.location.pathname !== "/" && <DrawerAppBar />}
    </Router>
  );
}

export default App;
