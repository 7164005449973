import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const HandleOddoProjects = ({ projects, token }) => {
  const [createdBoardIds, setCreatedBoardIds] = useState([]);
  const [createdGroupIds, setCreatedGroupIds] = useState([]);
  const [createdItemIds, setCreatedItemIds] = useState([]);

  useEffect(() => {
    const createMondayBoardsAndGroups = async () => {
      try {
        const boardAndGroupIds = await Promise.all(
          projects.map(async (project) => {
            // Create Monday board
            const createBoardQuery = `mutation { create_board (board_name: "${project.name}", board_kind: public) { id }}`;

            const createBoardResponse = await fetch(
              "https://api.monday.com/v2",
              {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  query: createBoardQuery,
                }),
              }
            );

            const boardData = await createBoardResponse.json();

            if (boardData.errors) {
              console.error(
                // `Error creating board for project "${project.name}":`,
                boardData.errors
              );
              return null;
            }

            const boardId = boardData.data.create_board.id;
            console.log(
              // `Board for project "${project.name}" created successfully. Board ID:`,
              boardId
            );

            // Fetch board columns
            const fetchColumnsQuery = `query { boards(ids: [${boardId}]) { columns { id title } }}`;

            const fetchColumnsResponse = await fetch(
              "https://api.monday.com/v2",
              {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  query: fetchColumnsQuery,
                }),
              }
            );

            const columnsData = await fetchColumnsResponse.json();

            if (columnsData.errors) {
              console.error(
                // `Error fetching columns for board "${boardId}":`,
                columnsData.errors
              );
              return null;
            }

            const columnIds = columnsData.data.boards[0].columns.map(
              (column) => column.id
            );
            // console.log(`Columns for board "${boardId}":`, columnIds);

            // Create Monday groups for each stage
            const groupPromises = project.stages.map(async (stage) => {
              const createGroupQuery = `mutation { create_group (board_id: ${boardId}, group_name: "${stage.name}") { id } }`;

              const createGroupResponse = await fetch(
                "https://api.monday.com/v2",
                {
                  method: "post",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({
                    query: createGroupQuery,
                  }),
                }
              );

              const groupData = await createGroupResponse.json();

              if (groupData.errors) {
                console.error(
                  // `Error creating group for project "${project.name}", stage "${stage.name}":`,
                  groupData.errors
                );
                return null;
              }

              const groupId = groupData.data.create_group.id;
              console.log(
                // `Group for project "${project.name}", stage "${stage.name}" created successfully. Group ID:`,
                groupId
              );

              // Create items within the group
              const itemPromises = project.tasks.map(async (taskGroup) => {
                // Assuming taskGroup.tasks is the array of tasks
                if( taskGroup.stageId === stage.id){
                
                const tasks = taskGroup.tasks;
                // Use the tasks array to create items
                const itemPromises = tasks.map(async (task) => {
                  const createItemQuery = `mutation { create_item (board_id: ${boardId}, group_id: "${groupId}", item_name: "${task.name}") { id }}`;

                  const createItemResponse = await fetch(
                    "https://api.monday.com/v2",
                    {
                      method: "post",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                      body: JSON.stringify({
                        query: createItemQuery,
                      }),
                    }
                  );

                  const itemData = await createItemResponse.json();

                  if (itemData.errors) {
                    console.error(
                      // `Error creating item for group "${groupId}", task "${task.name}":`,
                      itemData.errors
                    );
                    return null;
                  }

                  const itemId = itemData.data.create_item.id;
                  console.log(
                    // `Item for group "${groupId}", task "${task.name}" created successfully. Item ID:`,
                    itemId
                  );

                  return itemId;
                });

                const itemIds = await Promise.all(itemPromises);

                return itemIds;
            }});

              const itemIds = await Promise.all(itemPromises);

              return { groupId, itemIds };

            });

            const groupIds = await Promise.all(groupPromises);

            return { boardId, groupIds };
          })
        );

        // Filter out null values (in case of errors)
        const filteredBoardAndGroupIds = boardAndGroupIds.filter(
          (ids) => ids !== null
        );

        // Separate board, group, and item IDs
        const filteredBoardIds = filteredBoardAndGroupIds.map(
          (ids) => ids.boardId
        );
        const filteredGroupIds = filteredBoardAndGroupIds.map(
          (ids) => ids.groupIds
        );

        const filteredItemIds = filteredGroupIds.map(
          (ids) => ids.itemIds
        );

        setCreatedBoardIds(filteredBoardIds);
        setCreatedGroupIds(filteredGroupIds);
        setCreatedItemIds(filteredItemIds);

        toast.success("Projects imported successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          // className: "foo-bar",
        });
      } catch (error) {
        // console.error("Error creating Monday boards and groups:", error);

        toast.error("Import failed!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          // className: "foo-bar",
        });
      }
    };

    createMondayBoardsAndGroups();
  }, [token, projects]);

  return (
    <div>
      {/* Render other components or UI elements based on the created board and group IDs */}
    </div>
  );
};

export default HandleOddoProjects;
