const MondayBoards = async (token) => {
    let query = "query { boards { name id }}";
    try {    
        const response = await fetch("https://api.monday.com/v2", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                query: query,
            }),
        });
        const boardData = await response.json();

        if (boardData.data && boardData.data.boards) {
            const boardsData = Object.values(boardData.data.boards);
            // console.log("Boards data in MondayBoard:", boardsData);
            const detailedBoards = [];

            for (const board of boardsData) {
                const boardID = board.id;
                // console.log("Board ID:", boardID);

                const boardDetailsQuery = `
                    query {
                        boards(ids: [${boardID}]) {
                            id
                            name
                          items_page {
                            cursor
                            items {
                              id
                              name
                              group {
                                      id
                                      title
                                }
                            }
                          }
                        }
                      }                       
              `;

                const boardDetailsVariables = {
                    boardID: boardID,
                };

                const boardDetailsResponse = await fetch("https://api.monday.com/v2", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        query: boardDetailsQuery,
                        variables: boardDetailsVariables,
                    }),
                });

                const boardDetailsData = await boardDetailsResponse.json();
                // console.log("Board details data in MondayBoard:", boardDetailsData);
                if (boardDetailsData.data) {
                    detailedBoards.push(boardDetailsData.data.boards[0]);
                } else {
                    const errorMessage = `Failed to fetch details for board ${board.name}: ${JSON.stringify(
                        boardDetailsData.errors
                    )}`;
                    // console.error(errorMessage);
                }
            }

            // console.log("Detailed board data in MondayBoard:", detailedBoards);
            return detailedBoards;
        } else {
            // console.error("Invalid response format from Monday.com API");
            return [];
        }
    } catch (error) {
        // console.error("Error fetching board details:", error);

        return [];
    }
};

export default MondayBoards;
