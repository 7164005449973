import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import mondaySdk from "monday-sdk-js";
import Cookies from 'universal-cookie';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import './MondayAuth.css'; 
import mondaylogo from '../logo/mondaylogo.png';
import Dashboard from "../dashboard/Dashboard";
import DOMPurify from 'dompurify';

const cookies = new Cookies();
const MondayAuth = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [token, setToken] = useState('');
  const monday = mondaySdk();

  const handleConnectClick = () => {
    // Redirect to the authorization URL
    const sanitizedUrl = DOMPurify.sanitize('https://sync-odoo.dev-techloyce.com/auths');
    window.location.href = sanitizedUrl;
  };

  const exchangeCodeForToken = async (code) => {
    try {
      const response = await axios.get(`https://sync-odoo.dev-techloyce.com/monday-callback?code=${code}`);
      const accessToken = response.data.access_token;
      // console.log('Access token:', accessToken);
      
      if (accessToken) {
          cookies.set('access_token', accessToken, {
          path: '/',
          httpOnly: true,
          secure: true // Set to true if using HTTPS
        });

        monday.setToken(accessToken);
        setToken(accessToken);
        setIsAuthorized(true);
      }
    } catch (error) {
      console.error('Error exchanging code for tokens:', error);
    }
  };

  useEffect(() => {
    // Check if access token is available in localStorage
    // const storedAccessToken = localStorage.getItem('accessToken');
    const storedAccessToken = cookies.get('access_token');

    if (storedAccessToken) {
      // If access token is found, set it and mark as authorized
      monday.setToken(storedAccessToken);
      setToken(storedAccessToken);
      setIsAuthorized(true);
    } else {
      // Otherwise, check for authorization code in URL and proceed with token exchange
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get('code');

      if (authorizationCode && !isAuthorized) {
        exchangeCodeForToken(authorizationCode);
      }
    }
  }, [isAuthorized]); 

  return (
    <>
      {isAuthorized && token ? (
        <Dashboard token={token} />
      ) : (
        <div className="card-container">
          <Card style={{ width: '24rem', textAlign: 'center' }}>
            <Card.Img variant="top" src={mondaylogo} style={{ width: '18rem', margin: 'auto' }} />
            <Card.Body>
              <Card.Text>
                "Authorize your account to seamlessly connect with monday.com. Unlock powerful collaboration and project management features by clicking the 'Connect' button below."
              </Card.Text>
              <Button className="btn-connect" onClick={handleConnectClick}>
                Connect
              </Button>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};

export default MondayAuth;