import React, { useState,useEffect } from 'react';
import axios from 'axios';
import HandleOddoContacts from './HandleOddoContacts';
import { toast } from "react-toastify";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

const OdooContacts = ({token}) => {
  const [partners1, setPartners] = useState([]);

  // console.log('Api key in OdooContacts', token);

  const fetchData =  async () => {
    try {

      const response = await axios.post('https://sync-odoo.dev-techloyce.com/api/search-contacts');
      const { partners } = response.data;
      setPartners(partners);
      // console.log('Partners: ', partners);

      monday.execute('valueCreatedForUser');
      
    } catch (error) {
      // console.error('Error fetching contacts:', error);
      toast.error("Import failed.Error fetching contacts!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        // className: "foo-bar",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array to run the effect only once

  return (
    <div>
      {partners1.length>0 && <HandleOddoContacts partners = {partners1} token={token} />}
    </div>
  );
};

export default OdooContacts;
