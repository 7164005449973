import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast,ToastContainer } from "react-toastify";
import HandleFetchUsers from "../monday/HandleFetchUsers";
import HandleFetchBoards from "../monday/HandleFetchBoards";
import OdooLogin from "../odooConnect/OdooLogin";
import "./Dashboard.css";
import OdooContacts from "../monday/OdooContacts";
import OdooProjects from "../monday/OdooProjects";
import 'react-toastify/dist/ReactToastify.css';


const Dashboard = ({token}) => {

  // Move state declarations to the top
  const [exportContacts, setExportContacts] = useState(false);
  const [exportBoards, setExportBoards] = useState(false);

  const [importOptions, setImportOptions] = useState({
    importContacts: false,
    importBoards: false,
  });
  const [importButtonClicked, setImportButtonClicked] = useState(false);

  const handleExportClick1 = async () => {
    try {
      if (exportContacts) {
        await HandleFetchUsers(token);
        toast.success("Contacts exported successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          // className: "foo-bar",
        });
      }

      if (exportBoards) {
        await HandleFetchBoards(token);
        toast.success("Boards exported successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          // className: "foo-bar",
        });
      }
    } catch (error) {
      console.error("Error exporting data:", error);
      toast.error("Export failed!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        // className: "foo-bar",
      });
    }
  };

  const handleImportButtonClick = async () => {
    setImportButtonClicked(true);
  };

  const handleCheckboxChange = (checkboxName, value) => {
    // If any checkbox changes, reset importButtonClicked to false
    setImportButtonClicked(false);

    setImportOptions((prevOptions) => ({
      ...prevOptions,
      [checkboxName]: value,
    }));

    
  };

  return (
    <div className="main-div">
      <div className="odoo">
        <OdooLogin />
      </div>
      <div className="export-div1" style={{ backgroundColor: "white" }}>
        <FormGroup className="">
          <FormControlLabel
            control={<Checkbox
              className="checkbox"
              checked={exportContacts}
              onChange={() => setExportContacts(!exportContacts)}
            />}
            label="Export Contacts from monday to Odoo"
          />
          <FormControlLabel
            control={<Checkbox
              className="checkbox"
              checked={exportBoards}
              onChange={() => setExportBoards(!exportBoards)}
            />}
            label="Export Boards from monday to Odoo"
          />
          <button className="custom-width mx-auto p-2 bg-blue-500 text-white rounded" onClick={handleExportClick1}>
            Export
          </button>
        </FormGroup>
      </div>
      <div className="export-div2" style={{ backgroundColor: "white" }}>
        <FormGroup className="">
          <FormControlLabel
            control={
              <Checkbox
                className="checkbox"
                checked={importOptions.importBoards}
                onChange={(e) =>
                  handleCheckboxChange("importBoards", e.target.checked)
                }
              />
            }
            label="Import Boards in monday from Odoo"
          />

          <FormControlLabel
            control={
              <Checkbox
                className="checkbox"
                checked={importOptions.importContacts}
                onChange={(e) =>
                  handleCheckboxChange("importContacts", e.target.checked)
                }
              />
            }
            label="Import Contacts in monday from Odoo"
          />
          <button className="custom-width mx-auto p-2 bg-blue-500 text-white rounded" onClick={handleImportButtonClick}>
            Import
          </button>
          {importButtonClicked && (
            <>
              {importOptions.importContacts && 
                <OdooContacts token={token} />
              }
              {importOptions.importBoards && <OdooProjects token={token} />}
            </>
          )}
        </FormGroup>
      </div>
      <ToastContainer/>

    </div>
  );
};

export default Dashboard;
