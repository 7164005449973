const MondayUsers = async (token) => {
  const query = "query { users { name id }}";

  try {
    const response = await fetch("https://api.monday.com/v2", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: query,
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    // console.error("Error fetching users from Monday.com:", error);
    throw error; 
  }
};

export default MondayUsers;
